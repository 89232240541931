import { useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';

import { AuthLayout } from '../components/auth-layout/auth-layout';
import { BaseMessageView } from '../components/base-message-view';
import { updateAppVersion } from '../utils';

import { AuthenticatorProps } from '../types';

export const ConfirmationWasSent = (props: AuthenticatorProps) => {
  updateAppVersion();
  const [codeSending, setCodeSending] = useState(false);

  const onResend = () => {
    const username = props.authData.username;

    return Auth.resendSignUp(username, { type: 'signup' });
  };

  const handleResend = () => {
    if (codeSending) {
      return;
    }

    setCodeSending(true);

    onResend().finally(() => setCodeSending(false));
  };

  useEffect(() => {
    localStorage.setItem('amplify-authenticator-authState', 'confirmSignUp');
  }, []);

  const handleChangeState = (authState: string) => {
    props.onStateChange?.(authState);
  };

  if (props.authState !== 'confirmSignUp') {
    return null;
  }

  return (
    <AuthLayout animation={false}>
      <BaseMessageView
        title="Check your email"
        message="We sent an email to verify your account."
        action="Resend Verification Email"
        processing={codeSending}
        onAction={handleResend}
        onSignIn={() => handleChangeState('signIn')}
      />
    </AuthLayout>
  );
};
