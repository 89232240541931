import { useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import * as zod from 'zod';

import PlayStoreIcon from 'assets/images/android-badge.png';
import AppStoreIcon from 'assets/images/apple-badge.png';
import { ReactComponent as Logo } from 'assets/main-icons/label-logo.svg';
import { TextInput } from 'components/inputs/text-input';
import { AuthLayout } from '../components/auth-layout/auth-layout';
import { Button } from '../components/button';
import { Error } from '../components/form/error';
import { updateAppVersion } from '../utils';

import { AuthenticatorProps } from '../types';

import { useSearchParams } from 'modules/common/hooks/use-search-params';

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 4,
  },
  title: {
    fontWeight: 800,
    fontSize: {
      xs: '2rem',
      sm: '2.5rem',
    },
  },
  signIn: {
    text: {
      fontWeight: 600,
      fontSize: '1rem',
    },
    link: {
      color: 'primary.main',
      fontWeight: 600,
      fontSize: '1rem',
      cursor: 'pointer',
    },
  },
  appLink: {
    imgWrapper: {
      height: '55px',
      width: '190px',
      backgroundSize: 'auto',
    },
  },
};

type FormValues = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

const defaultValues: FormValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
};

const schema = zod.object({
  email: zod
    .string()
    .min(5, { message: 'Field is required' })
    .email({ message: 'Invalid email address' }),
  password: zod.string().min(3, { message: 'Field is required' }),
  firstName: zod.string().min(3, { message: 'Field is required' }),
  lastName: zod.string().min(3, { message: 'Field is required' }),
});

const SignUpComponent = (props: AuthenticatorProps) => {
  updateAppVersion();

  const { searchParams } = useSearchParams<{ templateId: string }>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    localStorage.setItem('amplify-authenticator-authState', 'signUp');
  }, []);

  useEffect(() => {
    if (searchParams.templateId) {
      localStorage.setItem('templateId', searchParams.templateId);
    }
  }, [searchParams.templateId]);

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeState = (authState: string, data?: any) => {
    props.onStateChange?.(authState, data);
  };

  const onSubmit = handleSubmit(async ({ email, firstName, lastName, password }: FormValues) => {
    setLoading(true);
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
          phone_number: '+11111111111',
        },
      });

      handleChangeState('confirmSignUp', { username: email });
      // window.location.href = '/';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  });

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <form onSubmit={onSubmit} style={{ height: '100%' }}>
      <Box sx={styles.container}>
        <Grid container spacing={4}>
          <Grid item xs={12} textAlign="center">
            <Logo />
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" sx={styles.title} align="center">
              Sign up to Rollo
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="email"
              type="email"
              control={control}
              errors={errors}
              placeholder="Email"
              variant="standard"
              inputProps={{
                autoCapitalize: 'none',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              name="firstName"
              control={control}
              placeholder="First name"
              errors={errors}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              name="lastName"
              control={control}
              placeholder="Last name"
              errors={errors}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="password"
              control={control}
              errors={errors}
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={(e) => e.preventDefault()}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Error msg={error} fontSize={18} />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          spacing={{
            xs: 3,
            sm: 4,
          }}>
          <Grid item xs={12}>
            <Button
              disabled={loading}
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              type="submit">
              Sign up
            </Button>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" gap={1}>
            <Typography component="span" sx={styles.signIn.text}>
              Already have an account?
            </Typography>
            <Typography
              component="span"
              onClick={() => handleChangeState('signIn')}
              sx={styles.signIn.link}>
              Sign in
            </Typography>
          </Grid>
          {!isMobile && (
            <Grid
              item
              xs={12}
              display={{
                xs: 'none',
                sm: 'flex',
              }}
              justifyContent="space-evenly"
              flexWrap="wrap"
              gap={2}>
              <CardMedia
                sx={styles.appLink.imgWrapper}
                component="a"
                href="https://apps.apple.com/us/app/rollo-design/id1606405048"
                target="_blank"
                rel="noopener noreferrer nofollow"
                image={AppStoreIcon}
              />

              <CardMedia
                sx={styles.appLink.imgWrapper}
                component="a"
                href="https://play.google.com/store/apps/details?id=com.rollo.designer"
                target="_blank"
                rel="noopener noreferrer nofollow"
                image={PlayStoreIcon}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </form>
  );
};

export const SignUp = (props: AuthenticatorProps) => {
  if (props.authState !== 'signUp') {
    return null;
  }

  return (
    <AuthLayout>
      <SignUpComponent {...props} />
    </AuthLayout>
  );
};
