import Cookies from 'js-cookie';

export const updateAppVersion = () => {
  const currentAppVersion = Cookies.get('designerAppVersion');
  const appVersion = process.env.REACT_APP_APP_VERSION;

  if (!currentAppVersion || currentAppVersion !== appVersion) {
    Cookies.set('designerAppVersion', appVersion || '', { expires: 90 });

    window.location.pathname = '/';
  }
};
