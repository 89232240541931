import { useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import * as zod from 'zod';

import { ReactComponent as Logo } from 'assets/main-icons/label-logo.svg';
import { TextInput } from 'components/inputs/text-input';
import { AuthLayout } from '../components/auth-layout/auth-layout';
import { BaseMessageView } from '../components/base-message-view';
import { Button } from '../components/button';
import { Error } from '../components/form/error';
import { updateAppVersion } from '../utils';

import { AuthenticatorProps } from '../types';

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 4,
  },
  title: {
    fontWeight: 800,
    fontSize: {
      xs: '2rem',
      sm: '2.5rem',
    },
  },
  signIn: {
    color: 'primary.main',
    fontWeight: 600,
    fontSize: '1rem',
    cursor: 'pointer',
  },
  appLink: {
    imgWrapper: {
      height: '50px',
      width: '180px',
      backgroundSize: 'contain',
    },
  },
};

type FormValues = {
  email: string;
};

const defaultValues: FormValues = {
  email: '',
};

const schema = zod.object({
  email: zod
    .string()
    .min(5, { message: 'Field is required' })
    .email({ message: 'Invalid email address' }),
});

export const ForgotPassword = (props: AuthenticatorProps) => {
  updateAppVersion();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [delivery, setDelivery] = useState<any>(null);

  useEffect(() => {
    localStorage.setItem('amplify-authenticator-authState', 'forgotPassword');
  }, []);

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeState = (authState: string, data?: any) => {
    props.onStateChange?.(authState, data);
  };

  const onSubmit = handleSubmit(async ({ email }: FormValues) => {
    setLoading(true);
    try {
      const result = await Auth.forgotPassword(email);
      setDelivery({ result, email });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code === 'InvalidParameterException') {
        await Auth.resendSignUp(email, { type: 'signup' });

        handleChangeState('confirmSignUp', { username: email });
      }
      setError(error.message);
    } finally {
      setLoading(false);
    }
  });

  const handleResend = () => {
    onSubmit(delivery);
  };

  if (props.authState !== 'forgotPassword') {
    return null;
  }

  if (delivery) {
    return (
      <AuthLayout animation={false}>
        <BaseMessageView
          title="Check Your Email Inbox"
          message="We sent an email to reset your password."
          action="Resend recovery email"
          processing={loading}
          onAction={handleResend}
          onSignIn={() => handleChangeState('signIn')}
        />
      </AuthLayout>
    );
  }

  return (
    <AuthLayout animation={false}>
      <form onSubmit={onSubmit} style={{ height: '100%' }}>
        <Box sx={styles.container}>
          <Grid container spacing={4}>
            <Grid item xs={12} textAlign="center">
              <Logo />
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" sx={styles.title} align="center">
                Forgot password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" align="center">
                Enter your Email below and we will send a message to reset your password.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="email"
                type="email"
                control={control}
                errors={errors}
                placeholder="Email"
                variant="standard"
                inputProps={{
                  autoCapitalize: 'none',
                }}
              />
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Error msg={error} fontSize={18} />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={{
              xs: 3,
              sm: 4,
            }}>
            <Grid item xs={12}>
              <Button
                disabled={loading}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                type="submit">
                Reset password
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="center"
                sx={styles.signIn}
                onClick={() => handleChangeState('signIn')}>
                Back to Sign In
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </form>
    </AuthLayout>
  );
};
