import { useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import * as zod from 'zod';

import { ReactComponent as Logo } from 'assets/main-icons/label-logo.svg';
import { TextInput } from 'components/inputs/text-input';
import { AuthLayout } from '../components/auth-layout/auth-layout';
import { Button } from '../components/button';
import { Error } from '../components/form/error';
import { updateAppVersion } from '../utils';

import { AuthenticatorProps } from '../types';

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 4,
  },
  title: {
    fontWeight: 800,
    fontSize: {
      xs: '2rem',
      sm: '2.5rem',
    },
  },
  signIn: {
    color: 'primary.main',
    fontWeight: 600,
    fontSize: '1rem',
    cursor: 'pointer',
  },
  appLink: {
    imgWrapper: {
      height: '50px',
      width: '180px',
      backgroundSize: 'contain',
    },
  },
};

type FormValues = {
  code: string;
};

const defaultValues: FormValues = {
  code: '',
};

const schema = zod.object({
  code: zod.string().min(1, { message: 'Field is required' }),
});

export const ConfirmSignIn = (props: AuthenticatorProps) => {
  const { authData, authState, onStateChange } = props;

  updateAppVersion();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    localStorage.setItem('amplify-authenticator-authState', 'confirmSignIn');
  }, []);

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeState = (authState: string, data?: any) => {
    onStateChange?.(authState, data);
  };

  const onSubmit = handleSubmit(async ({ code }: FormValues) => {
    setLoading(true);

    try {
      await Auth.confirmSignIn(authData, code, authData.challengeName);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(error.message ? error.message : JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  });

  if (authState !== 'confirmSignIn') {
    return null;
  }

  return (
    <AuthLayout animation={false}>
      <form onSubmit={onSubmit} style={{ height: '100%' }}>
        <Box sx={styles.container}>
          <Grid container spacing={4}>
            <Grid item xs={12} textAlign="center">
              <Logo />
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" sx={styles.title} align="center">
                Enter MFA code
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" align="center">
                {authData.challengeName === 'SMS_MFA' ? (
                  <>
                    One-time password has been sent to your email.
                    <br />
                    Enter the code to confirm login.
                  </>
                ) : (
                  <>
                    Authenticator App Verification.
                    <br />
                    Enter the 6-digit code generated by the Authenticator App.
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="code"
                control={control}
                errors={errors}
                placeholder="Code"
                variant="standard"
                inputProps={{
                  autoCapitalize: 'none',
                }}
              />
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Error msg={error} fontSize={18} />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={{
              xs: 3,
              sm: 4,
            }}>
            <Grid item xs={12}>
              <Button
                disabled={loading}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                type="submit">
                Continue
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="center"
                sx={styles.signIn}
                onClick={() => handleChangeState('signIn')}>
                Back to Sign In
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </form>
    </AuthLayout>
  );
};
