import { lazy, Suspense, useEffect } from 'react';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { isAndroid, isIOS, isIOS13 } from 'react-device-detect';

import PlayStoreIcon from 'assets/images/android-badge.png';
import AppStoreIcon from 'assets/images/apple-badge.png';
import AuthBackground from 'assets/images/mobile-auth-background.png';
import { Fallback } from '../components/auth-layout/fallback';
import { Button } from '../components/button';
import { updateAppVersion } from '../utils';

import { AuthenticatorProps } from '../types';

import { ThemeProvider } from 'modules/common/theme-provider';

const Carousel = lazy(() => import('../components/auth-layout/carousel'));

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${AuthBackground})`,
  },
  innerContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    gap: '1rem',
  },
  signInButton: {
    margin: '0 1rem',
    background: '#ffffff',
    color: '#6C13F9',
    fontSize: 18,
    '&:hover': {
      background: '#ffffff',
    },
  },
  signUpButton: {
    margin: '0 1rem',
    background: 'transparent',
    color: '#ffffff',
    fontSize: 18,
  },
  appLink: {
    height: '55px',
    width: '190px',
    backgroundSize: 'auto',
    margin: '0 auto',
  },
};

const HELPER_ROUTES = ['/confirm-signup', '/reset-password'];

export const MobilePreview = (props: AuthenticatorProps) => {
  updateAppVersion();

  const urlParams = new URLSearchParams(window.location.search);
  const webViewType = urlParams.get('webview');
  const authPage = urlParams.get('authPage');

  const handleChangeState = (authState: string) => {
    props.onStateChange?.(authState);
  };

  useEffect(() => {
    if (authPage === 'signin') {
      props.onStateChange?.('signIn');
    }
  }, [authPage, props]);

  useEffect(() => {
    localStorage.setItem('amplify-authenticator-authState', 'mobilePreview');
  }, []);

  // It is a hack to skip the mobile preview screen
  useEffect(() => {
    if (HELPER_ROUTES.includes(window.location.pathname)) {
      props.onStateChange?.('signIn');
    }
  }, [props]);

  if (props.authState !== 'mobilePreview') {
    return null;
  }

  return (
    <ThemeProvider>
      <Box sx={styles.container}>
        <Box sx={styles.innerContainer}>
          <Suspense fallback={<Fallback />}>
            <Carousel />
          </Suspense>
          {webViewType === 'mobile' ? (
            <>
              <Button
                variant="contained"
                size="large"
                sx={styles.signInButton}
                onClick={() => handleChangeState('signIn')}>
                Sign In
              </Button>
              <Button
                variant="text"
                size="large"
                color="primary"
                sx={styles.signUpButton}
                onClick={() => handleChangeState('signUp')}>
                Sign Up
              </Button>
            </>
          ) : (
            <>
              {(isIOS || isIOS13) && (
                <CardMedia
                  sx={styles.appLink}
                  component="a"
                  href="https://apps.apple.com/us/app/rollo-design/id1606405048"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  image={AppStoreIcon}
                />
              )}

              {isAndroid && (
                <CardMedia
                  sx={styles.appLink}
                  component="a"
                  href="https://play.google.com/store/apps/details?id=com.rollo.designer"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  image={PlayStoreIcon}
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
